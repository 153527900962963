<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import API from "@/plugins/axios-method";
import store from "@/state/store";
import DataLoader from "@/components/data-loader.vue";
import LineChart from "@/components/charts/line-chart.vue";

export default {
  components: {LineChart, DataLoader, PageHeader, StatCard, Layout},
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  data() {
    return {
      year: new Date().getFullYear(),
      url : "",
      commercial_count: 0,
      total_deposit: 0,
      total_registration_fees: 0,
      network_fees_on_deposit: 0,
      network_fees_on_subscription: 0,
      commercials: 0,
      members: 0,
      supervisors: 0,
      savingPerMonthChart: {
        labels: [],
        data: []
      },
      showDataLoader: true
    };
  },
  mounted() {
    this.url = '/statistic/coordinator/' + store.getters['auth/userId'] + '?year=' + this.year
    this.getStats()
  },
  methods: {
    getStats(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              const statistics = response.data
              this.commercial_count = statistics.users
              this.total_deposit = statistics.deposit
              this.total_registration_fees = statistics.registration
              this.network_fees_on_deposit = statistics.contributionNetworkFees
              this.network_fees_on_subscription = statistics.subscriptionNetworkFees
              this.commercials = statistics.commercials
              this.members = statistics.members
              this.supervisors = statistics.supervisors

              const savingPerMonth = statistics.transactionsPerMonth


              for (const month in savingPerMonth) {
                this.savingPerMonthChart.labels.push(month)
                this.savingPerMonthChart.data.push(savingPerMonth[month])
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :show-add-button="false" :title="'Superviseur'" :show-search="false" />
    <section>
      <div class="row">
        <div class="col-12">
          <h5 class="text-decoration-underline mb-3 pb-1">Statistiques Coordinateur</h5>
        </div>
      </div>
      <data-loader v-if="showDataLoader"/>
      <div class="row" v-else-if="!showDataLoader">
        <StatCard
            :amount="total_registration_fees"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Frais d\'Inscriptions'"
            :to-link="'/admin/transaction/deposits'"
        />
        <StatCard
            :amount="total_deposit"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Epargne Totale'"
            :to-link="'/epargnes'"
        />
        <StatCard
            :amount="network_fees_on_deposit"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Frais sur epargne'"
            :to-link="'/epargnes'"
        />
        <StatCard
            :amount="network_fees_on_subscription"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Frais sur souscription'"
            :to-link="'/epargnes'"
        />
        <StatCard
            :title="'Superviseurs'"
            :to-link="'/utilisateurs'"
            :amount="supervisors"
            :is-increase="true"
            :percentage="35"
            :icon-bg="'primary'"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Commerciaux'"
            :to-link="'/utilisateurs'"
            :amount="commercials"
            :is-increase="true"
            :percentage="35"
            :icon-bg="'primary'"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Adherents'"
            :to-link="'/utilisateurs'"
            :amount="members"
            :is-increase="true"
            :percentage="35"
            :icon-bg="'primary'"
            :icon="'user-circle'"
        />
      </div>
      <div class="row" v-if="!showDataLoader">
        <div class="col">
          <LineChart
              :labels="savingPerMonthChart.labels"
              :data="savingPerMonthChart.data"
              :title="'Entrées en ' + year"
              :year="year"
          />
        </div>
      </div>
    </section>
  </Layout>
</template>